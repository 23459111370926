import React, { useState, useEffect } from 'react'
import PlayArrowIcon from '@mui/icons-material/PlayArrow'
import RefreshIcon from '@mui/icons-material/Refresh'
import Stack from '@mui/material/Stack'
import ReactPlayer from 'react-player'

import config from '../config'
import { SessionProps } from '../models'
import Button from '@mui/material/Button'
import LinearProgress from '@mui/material/LinearProgress'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'

interface Video {
  video_uid: string
  title: string
  channel: string
  video_duration: number
}

interface ListResponse {
  items: Video[]
  last_evaluated_key?: string
}

interface PlayerProps {
  sessionProps?: SessionProps
}

const Player = (props: PlayerProps): JSX.Element => {
  const { sessionProps } = props

  const [url, setUrl] = useState('')
  const [rows, setRows] = useState<Video[]>([])
  const [showProgressBar, setShowProgressBar] = useState(false)

  const handlePlay = async (videoUid: string): Promise<void> => {
    if (sessionProps == null) return
    if (!sessionProps.groups.includes('sakeet')) return
    const requestOptions = {
      method: 'GET',
      headers: {
        Authorization: sessionProps.idToken,
        Accept: 'binary/octet-stream'
      }
    }

    // revoke previous url to free memory
    if (url !== '') {
      URL.revokeObjectURL(url)
    }

    setShowProgressBar(true)
    try {
      const response = await fetch(`${config.ApiBaseUrl}/download/${videoUid}`, requestOptions)
      const status = response.status
      console.log('File download response', status)
      if (status !== 200) return undefined
      const blob = await response.blob()
      console.log('Got binary blob of size/type ', blob.size, blob.type)
      setUrl(URL.createObjectURL(blob))
    } finally {
      setShowProgressBar(false)
    }
  }

  const refreshVideoList = async (): Promise<void> => {
    if (sessionProps == null) return
    if (!sessionProps.groups.includes('sakeet')) return

    const getListChunk = async (startKey?: string): Promise<ListResponse | null> => {
      const requestOptions = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: sessionProps.idToken
        }
      }
      const queryParams = (startKey != null) ? `?${new URLSearchParams({ scan_start_id: startKey }).toString()}` : ''
      const response = await fetch(`${config.ApiBaseUrl}/list${queryParams}`, requestOptions)
      const status = await response.status
      if (status !== 200) return null
      return (await response.json() as ListResponse)
    }

    setShowProgressBar(true)
    try {
      let videoList: Video[] = []
      let success = true
      let lastEvaluatedKey
      let counter = 0 // sanity check
      do {
        const chunk: ListResponse | null = await getListChunk(lastEvaluatedKey)
        if (chunk === null) {
          console.log('Invalid response from getListChunk, canceling')
          success = false
          break
        }
        videoList = videoList.concat(chunk.items)
        lastEvaluatedKey = chunk.last_evaluated_key
        counter += 1
      } while (lastEvaluatedKey != null && counter < 10)

      if (!success) {
        console.log('Failed to download list')
        setRows([])
      }

      console.log('Updating video list: ', videoList.length)
      setRows(videoList)
    } finally {
      setShowProgressBar(false)
    }
  }

  const toDisplayDuration = (duration: number): string => {
    const hours = Math.floor(duration / 3600)
    const minutes = Math.floor(duration % 3600 / 60)
    const seconds = Math.floor(duration % 3600 % 60)

    const hDisplay = hours > 0 ? `${hours}h ` : ''
    const mDisplay = minutes > 0 ? `${minutes}m ` : ''
    const sDisplay = `${seconds}s`
    return `${hDisplay}${mDisplay}${sDisplay}`
  }

  useEffect(() => {
    // Refresh video list on page load
    refreshVideoList()
      .catch(console.error)
  }, [])

  return (
    <div className="player">
      <Stack spacing={1}>
        <ReactPlayer
          width="100%"
          height="100%"
          playing={true}
          url={url}
          onError={(e, data) => console.log(`error: ${String(e)} - ${String(data)}`)}
          onReady={(player) => console.log('ready')}
          onStart={() => console.log('start')}
          onPlay={() => console.log('play')}
          controls={true}
        />
        {showProgressBar && <LinearProgress />}
        <TableContainer>
          <Table size="small" aria-label="content list">
            <TableHead>
              <TableRow>
                <TableCell colSpan={2}>Videos</TableCell>
                <TableCell align="right">
                  <Button
                    variant="outlined"
                    startIcon={<RefreshIcon />}
                    onClick={() => { void refreshVideoList() }}
                    sx={{
                      width: '2em',
                      height: '2em',
                      padding: '0.1em',
                      minWidth: 0,
                      '& .MuiButton-startIcon': { margin: 0 }
                    }}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Title</TableCell>
                <TableCell align="left">Channel</TableCell>
                <TableCell align="right">Duration</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow
                  key={row.video_uid}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    <Button
                      variant="outlined"
                      startIcon={<PlayArrowIcon />}
                      onClick={() => { void handlePlay(row.video_uid) }}
                      sx={{
                        width: '2em',
                        height: '2em',
                        padding: '0.1em',
                        minWidth: 0,
                        '& .MuiButton-startIcon': { margin: 0 }
                      }}
                    />
                    &nbsp;{row.title}
                  </TableCell>
                  <TableCell align="left">{row.channel}</TableCell>
                  <TableCell align="right">{toDisplayDuration(row.video_duration)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>
    </div>
  )
}

export default Player
