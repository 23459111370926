import { CloudUpload } from '@mui/icons-material'
import Alert, { AlertColor } from '@mui/material/Alert'
import Button from '@mui/material/Button'
import Collapse from '@mui/material/Collapse'
import Stack from '@mui/material/Stack'
import Tooltip from '@mui/material/Tooltip'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import React, { FormEvent, useState } from 'react'

import config from '../config'
import { SessionProps } from '../models'
import { FormTitle } from './form/FormTitle'
import { UploadField } from './form/UploadField'

interface TopBarProps {
  sessionProps?: SessionProps
}

interface UploadAlert {
  show: boolean
  severity: AlertColor
  message: string
}

const UploadForm = (props: TopBarProps): JSX.Element => {
  const { sessionProps } = props
  const [url, setUrl] = useState<string>('')
  const okMessageDisplayDuration = 3000 // milliseconds
  const [uploadAlert, setUploadAlert] = useState<UploadAlert>({ show: false, severity: 'success', message: '' })

  const invokeUploadLambda = async (url: string): Promise<void> => {
    if (sessionProps == null) return
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: sessionProps.idToken
      },
      body: JSON.stringify({ url })
    }
    const response = await fetch(`${config.ApiBaseUrl}/upload`, requestOptions)
    const status = await response.status
    console.log('Upload response', status)
    if (status === 200) {
      setUploadAlert({ show: true, severity: 'success', message: 'Upload successfully initiated' })
      // since OK, clear the message after a bit
      setTimeout(() => {
        setUploadAlert({ ...uploadAlert, show: false })
      }, okMessageDisplayDuration)
    } else {
      setUploadAlert({ show: true, severity: 'error', message: `Upload failed with ${status}` })
    }
  }

  const upload = (event: React.FormEvent): void => {
    event.preventDefault()

    invokeUploadLambda(url)
      .then(() => {
        setUrl('')
      })
      .catch((err) => {
        setUploadAlert({ show: true, severity: 'error', message: `Upload failed with ${err as string}` })
        console.error('Upload failed', err)
      })
  }

  const onSubmit = (event: FormEvent): void => {
    upload(event)
  }

  const UploadButton = (): JSX.Element => {
    return (
      <Tooltip title="Upload to Cloud">
        <Button type="submit" variant="outlined" startIcon={<CloudUpload />}>
          Upload
        </Button>
      </Tooltip>
    )
  }

  return (
      <Stack
        component="form"
        sx={{
          border: '1px dashed grey',
          width: '35ch',
          alignItems: 'center',
          '& > :not(style)': { margin: 2, width: '30ch' }
        }}
        noValidate
        autoComplete="off"
        onSubmit={onSubmit}
      >
        <FormTitle text={'Upload'} />
        <UploadField url={url} setUrl={setUrl} />
        <UploadButton />
        <Collapse in={uploadAlert.show}>
          <Alert
            severity={uploadAlert.severity}
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setUploadAlert({ ...uploadAlert, show: false })
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
            sx={{ mb: 2 }}
            >
            {uploadAlert.message}
          </Alert>
        </Collapse>
      </Stack>
  )
}

export default UploadForm
